<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import axios from "axios";
import mobileView from "../../../components/mobile-view.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Transaksi Keluar",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Transaksi Stok",
          active: true,
        },
      ],
      screenWidth: 0,
      clothCategoryUrl: process.env.VUE_APP_API_BASE_URL + "cloth-categories",
      sizeUrl: process.env.VUE_APP_API_BASE_URL + "sizes",
      colorUrl: process.env.VUE_APP_API_BASE_URL + "colors",
      apiUrl: process.env.VUE_APP_API_BASE_URL + "stock-transactions",
      createdBy: [],
      dataKey: "stock",
      sizes: [],
      cloth_category: [],
      colors: [],
      columns: [
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          date: true,
          center: false,
          width: 110,
        },
        {
          prop: "cloth_size.cloth_color.cloth.cloth_category.name",
          label: "Pakaian",
          center: false,
          width: 130,
        },
        {
          prop: "cloth_size",
          label: "Warna",
          center: true,
          colorTransaction: true,
          width: 100,
        },
        {
          prop: "cloth_size.size.name",
          label: "Ukuran",
          center: true,
          width: 80,
        },
        {
          prop: "previous_stock",
          label: "Stok Sebelumnya",
          center: true,
          width: 150,
        },
        {
          prop: "stock_in",
          label: "Stok Masuk",
          center: true,
          stockin: true,
          width: 100,
        },
        {
          prop: "stock_out",
          label: "Stok Keluar",
          center: true,
          stockout: true,
          width: 100,
        },
        {
          prop: "current_stock",
          label: "Stok Saat Ini",
          center: true,
          width: 120,
        },
        {
          prop: "note",
          label: "Keterangan",
          left: true,
          width: 120,
        },
        {
          prop: "created_by.name",
          label: "Dibuat Oleh",
          center: true,
          user: true,
          width: 120,
        },
      ],
    };
  },
  methods: {
    async getSize() {
      try {
        await axios.get(this.sizeUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.sizes = uniqueRoles.map((size) => ({
            label: size.name,
            value: size.name,
            id: size.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getClothCategory() {
      try {
        await axios.get(this.clothCategoryUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.cloth_category = uniqueRoles.map((category) => ({
            value: category.name,
            label: category.name,
            id: category.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getColors() {
      try {
        await axios.get(this.colorUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.colors = uniqueRoles.map((color) => ({
            value: color.name,
            label: color.name,
            id: color.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.getSize();
    this.getClothCategory();
    this.getColors();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-form-add="true"
          :form-url="'/stok/tambah'"
          :with-filter-default="false"
          :with-filter-cloth-category="true"
          :with-filter-name="true"
          :with-filter-color-multiple="true"
          :with-filter-size-multiple="true"
          :with-filter-category="true"
          :select-colors="colors"
          :with-filter-created-complete="true"
          :with-filter-date="true"
          :with-fixed="true"
          :with-delete="false"
          :with-print="false"
          :with-edit="false"
          :with-show="false"
          :no-action="true"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :action="false"
          :select-options="sizes"
          :category-option="cloth_category"
          :with-add="false"
          title-filter="Filter Berdasarkan Nama Pakaian"
          filter-with="cloth_name"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    filter-with="cloth_name"
    title-filter="Filter Berdasarkan Nama Pakaian"
    :with-filter-text="true"
    :with-filter-color="true"
    :with-filter-category="true"
    :with-filter-date="true"
    :with-filter-created="true"
    :form-url="'/produk/ukuran/tambah'"
    :data-url="apiUrl"
    :card-default="false"
    :card-transaction="true"
    :with-add-data="false"
    :with-current-page="25"
    skeleton-radius="5px"
  ></mobileView>
</template>

<style></style>
